import React, { lazy, Suspense } from "react";

import ModuloControles from "../pages/ModuloControles";
import ModuloFinanceiro from "../pages/ModuloFinanceiro";
import ModuloAtendimento from "../pages/ModuloAtendimento";
import ModuloFiscal from "../pages/ModuloFiscal";
import ModuloVendas from "../pages/ModuloVendas";

import Home from "../pages/Home";
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ "../pages/Profile"));
const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ "../pages/Dashboard"));

//Intranet
const Intranet = lazy(() => import(/* webpackChunkName: "Intranet" */ "../pages/Intranet"));
const ColabPerfil = lazy(() => import(/* webpackChunkName: "ColabPerfil" */ "../pages/Intranet/components/ColabPerfil"));


const ControlesCadastrosPerfilDeAcessoList = lazy(() => import(/* webpackChunkName: "ControlesCadastrosPerfilDeAcessoList" */ "../pages/ModuloControles/pages/CadPerfil/list"));
const ControlesCadastrosPerfilDeAcessoForm = lazy(() => import(/* webpackChunkName: "ControlesCadastrosPerfilDeAcessoForm" */ "../pages/ModuloControles/pages/CadPerfil/form"));
const ControlesCadastrosFuncionariosList = lazy(() => import(/* webpackChunkName: "ControlesCadastrosFuncionariosList" */ "../pages/ModuloControles/pages/Funcionarios/Funcionarios"));
const ControlesCadastrosFuncionariosForm = lazy(() => import(/* webpackChunkName: "ControlesCadastrosFuncionariosForm" */ "../pages/ModuloControles/pages/Funcionarios/CadastroFuncionario"));
const ControlesCadastrosDepartamentosList = lazy(() => import(/* webpackChunkName: "ControlesCadastrosDepartamentosList" */ "../pages/ModuloControles/pages/Departamentos/DepartamentosGrid"));
const ControlesCadastrosDepartamentosForm = lazy(() => import(/* webpackChunkName: "ControlesCadastrosDepartamentosForm" */ "../pages/ModuloControles/pages/Departamentos/CadastroDepartamentoForm"));
const ControlesCadastrosFuncoesList = lazy(() => import(/* webpackChunkName: "ControlesCadastrosFuncoesList" */ "../pages/ModuloControles/pages/Funcoes/Lista"));
const ControlesCadastrosFuncoesForm = lazy(() => import(/* webpackChunkName: "ControlesCadastrosFuncoesForm" */ "../pages/ModuloControles/pages/Funcoes/Cadastro"));

const AtendimentoChamadosPainelAtendimentoList = lazy(() => import(/* webpackChunkName: "AtendimentoChamadosPainelAtendimentoList" */ '../pages/ModuloAtendimento/PainelControleAtendimentoFinanceiro'));
const AtendimentoChamadosAtendimentoFinanceiroResponder = lazy(() => import(/* webpackChunkName: "AtendimentoChamadosAtendimentoFinanceiroResponder" */ '../pages/ModuloAtendimento/AtendimentoFinanceiro/AtendimentoResponder'));

const AtendimentoChamadosAtendimentoFinanceiroList = lazy(() => import(/* webpackChunkName: "AtendimentoChamadosAtendimentoFinanceiroList" */ "../pages/ModuloAtendimento/AtendimentoFinanceiro/AtendimentosFinanceiro"));
const AtendimentoChamadosAtendimentoFinanceiroForm = lazy(() => import(/* webpackChunkName: "AtendimentoChamadosAtendimentoFinanceiroForm" */ "../pages/ModuloAtendimento/AtendimentoFinanceiro/Atendimento"));

const FinanceiroClientesGruposDeClientesList = lazy(() => import(/* webpackChunkName: "FinanceiroClientesGruposDeClientesList" */ "../pages/ModuloFinanceiro/GrupoClientes"));
const FinanceiroClientesGruposDeClientesForm = lazy(() => import(/* webpackChunkName: "FinanceiroClientesGruposDeClientesForm" */ "../pages/ModuloFinanceiro/GrupoClientes/GrupoClientes"));
const FinanceiroClientesMotivoDeStatusDeClientesList = lazy(() => import(/* webpackChunkName: "FinanceiroClientesMotivoDeStatusDeClientesList" */ "../pages/ModuloFinanceiro/MotivoStatusCliente/list"));
const FinanceiroClientesMotivoDeStatusDeClientesForm = lazy(() => import(/* webpackChunkName: "FinanceiroClientesMotivoDeStatusDeClientesForm" */ "../pages/ModuloFinanceiro/MotivoStatusCliente/form"));


const FiscalCestSegmentosList = lazy(() => import(/* webpackChunkName: "FiscalCestSegmentosList" */ "../pages/ModuloFiscal/pages/Segmento/SegmentoFilter"));
const FiscalCestSegmentosForm = lazy(() => import(/* webpackChunkName: "FiscalCestSegmentosForm" */ "../pages/ModuloFiscal/pages/Segmento/Segmento"));
const FiscalNcmList = lazy(() => import(/* webpackChunkName: "FiscalNcmList" */ "../pages/ModuloFiscal/pages/NCM"));
const FiscalNcmForm = lazy(() => import(/* webpackChunkName: "FiscalNcmForm" */ "../pages/ModuloFiscal/pages/NCM/NcmForm"));
const FiscalCestCadastroList = lazy(() => import(/* webpackChunkName: "FiscalCestCadastroList" */ "../pages/ModuloFiscal/pages/CEST"));
const FiscalCestCadastroForm = lazy(() => import(/* webpackChunkName: "FiscalCestCadastroForm" */ "../pages/ModuloFiscal/pages/CEST/CestForm"));
const FiscalCrtList = lazy(() => import(/* webpackChunkName: "FiscalCrtList" */ "../pages/ModuloFiscal/pages/CRT"));
const FiscalCstCstIcmsList = lazy(() => import(/* webpackChunkName: "FiscalCstCstIcmsList" */ "../pages/ModuloFiscal/pages/CST"));
const FiscalCstCstIcmsForm = lazy(() => import(/* webpackChunkName: "FiscalCstCstIcmsForm" */ "../pages/ModuloFiscal/pages/CST/CSTForm"));
const FiscalOrigemDaMercadoriaList = lazy(() => import(/* webpackChunkName: "FiscalOrigemDaMercadoriaList" */ "../pages/ModuloFiscal/pages/OrigemMercadoria"));
const FiscalConfigNaturezaList = lazy(() => import(/* webpackChunkName: "FiscalCstConfigNaturezaList"*/ "../pages/ModuloFiscal/pages/ConfigNatOperacao/list"));
const FiscalConfigNaturezaForm = lazy(() => import(/* webpackChunkName: "FiscalCstConfigNaturezaForm"*/ "../pages/ModuloFiscal/pages/ConfigNatOperacao/form"));
const FiscalCfopList = lazy(() => import(/* webpackChunkName: "FiscalCfopList" */ "../pages/ModuloFiscal/pages/Cfop/Cfop"));
const FiscalCfopForm = lazy(() => import(/* webpackChunkName: "FiscalCfopForm" */ "../pages/ModuloFiscal/pages/Cfop/CadastroCfop"));
const FiscalRegrasPorUfList = lazy(() => import(/* webpackChunkName: "FiscalRegrasPorUfList" */ "../pages/ModuloFiscal/pages/UfRegras/list"));
const FiscalRegrasPorUfForm = lazy(() => import(/* webpackChunkName: "FiscalRegrasPorUfForm" */ "../pages/ModuloFiscal/pages/UfRegras/form"));
const FiscalCstCstPisCofinsList = lazy(() => import(/* webpackChunkName: "FiscalCstCstPisCofinsList" */ "../pages/ModuloFiscal/pages/PisCofins/list"));
const FiscalCstCstPisCofinsForm = lazy(() => import(/* webpackChunkName: "FiscalCstCstPisCofinsForm" */ "../pages/ModuloFiscal/pages/PisCofins/form"));
const FiscalIcmsList = lazy(() => import(/* webpackChunkName: "FiscalIcmsList" */ "../pages/ModuloFiscal/pages/ICMS/form"));
const FiscalPagamentosMeioDePagtoList = lazy(() => import(/* webpackChunkName: "FiscalPagamentosMeioDePagtoList" */ "../pages/ModuloFiscal/pages/MeioPagamento/list"));
const FiscalPagamentosMeioDePagtoForm = lazy(() => import(/* webpackChunkName: "FiscalPagamentosMeioDePagtoForm" */ "../pages/ModuloFiscal/pages/MeioPagamento/form"));
const FiscalAtividadeEconomicaList = lazy(() => import(/* webpackChunkName: "FiscalAtividadeEconomicaList" */ "../pages/ModuloFiscal/pages/AtividadeEconomica/list"));
const FiscalMotivosDeCancelamentoList = lazy(() => import(/* webpackChunkName: "FiscalMotivosDeCancelamentoList" */ "../pages/ModuloFiscal/pages/MotivosCancelamento/list"));
const FiscalMotivosDeCancelamentoForm = lazy(() => import(/* webpackChunkName: "FiscalMotivosDeCancelamentoForm" */ "../pages/ModuloFiscal/pages/MotivosCancelamento/form"));
const FiscalPagamentosBandeiraList = lazy(() => import(/* webpackChunkName: "FiscalPagamentosBandeiraList" */ "../pages/ModuloFiscal/pages/Bandeira/list"));
const FiscalPagamentosBandeiraForm = lazy(() => import(/* webpackChunkName: "FiscalPagamentosBandeiraForm" */ "../pages/ModuloFiscal/pages/Bandeira/form"));
const FiscalPagamentosBancoList = lazy(() => import(/* webpackChunkName: "FiscalPagamentosBancoList" */ "../pages/ModuloFiscal/pages/Banco/list"));
const FiscalPagamentosBancoForm = lazy(() => import(/* webpackChunkName: "FiscalPagamentosBancoForm" */ "../pages/ModuloFiscal/pages/Banco/form"));


const FallBack = () => {
  return <></>
}

const getComponent = (componentName) => {
  const components = {
    ModuloControles,
    ModuloFinanceiro,
    ModuloAtendimento,
    ModuloFiscal,
    ModuloVendas,

    Home,
    Profile,
    Dashboard,
    Intranet,
    ColabPerfil,

    ControlesCadastrosPerfilDeAcessoList,
    ControlesCadastrosPerfilDeAcessoForm,
    ControlesCadastrosFuncionariosList,
    ControlesCadastrosFuncionariosForm,
    ControlesCadastrosDepartamentosList,
    ControlesCadastrosDepartamentosForm,
    ControlesCadastrosFuncoesList,
    ControlesCadastrosFuncoesForm,

    AtendimentoChamadosPainelAtendimentoList,
    AtendimentoChamadosAtendimentoFinanceiroList,
    AtendimentoChamadosAtendimentoFinanceiroForm,
    AtendimentoChamadosAtendimentoFinanceiroResponder,

    FinanceiroClientesGruposDeClientesList,
    FinanceiroClientesGruposDeClientesForm,
    FinanceiroClientesMotivoDeStatusDeClientesList,
    FinanceiroClientesMotivoDeStatusDeClientesForm,


    FiscalCestSegmentosList,
    FiscalCestSegmentosForm,
    FiscalNcmList,
    FiscalNcmForm,
    FiscalCestCadastroList,
    FiscalCestCadastroForm,
    FiscalCrtList,
    FiscalCstCstIcmsList,
    FiscalCstCstIcmsForm,
    FiscalOrigemDaMercadoriaList,
    FiscalConfigNaturezaList,
    FiscalConfigNaturezaForm,
    FiscalCfopList,
    FiscalCfopForm,
    FiscalRegrasPorUfList,
    FiscalRegrasPorUfForm,
    FiscalCstCstPisCofinsList,
    FiscalCstCstPisCofinsForm,
    FiscalIcmsList,
    FiscalPagamentosMeioDePagtoList,
    FiscalPagamentosMeioDePagtoForm,
    FiscalAtividadeEconomicaList,
    FiscalMotivosDeCancelamentoList,
    FiscalMotivosDeCancelamentoForm,
    FiscalPagamentosBandeiraList,
    FiscalPagamentosBandeiraForm,
    FiscalPagamentosBancoList,
    FiscalPagamentosBancoForm,
  };

  return components[componentName] || FallBack;
};

const RouteComponents = ({ component, ...props }) => {
  const Component = getComponent(component);

  return (
    <Suspense fallback={<>...</>}>
      <Component {...props} />
    </Suspense>
  );
};

export default RouteComponents;
