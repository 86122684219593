import axiosService from "../components/Utils/axios";
import endPoint from "../utils/endpoints";
import authService from './authService';
import profile from "./controleAcessoServices/profile"

class ControleAcessoService {
    constructor(services) {
        Object.assign(this, services)
    }

    GetMenus = (ativo) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            axiosService.axios.get(`${endPoint.ControleDeAcesso.GetMenus}/${ativo}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    GetMenusDefault = (ativo) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            axiosService.axios.get(`${endPoint.ControleDeAcesso.GetMenusDefault}/${ativo}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    GetPerfis = (ativo) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            axiosService.axios.get(`${endPoint.ControleDeAcesso.GetPerfis}/${ativo}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    GetPerfil_Legado = (cod_descricao) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            // axiosService.axios.get(`/usuarios/Profile/Legado/${cod_descricao}`, {headers: {'X-No-Redirect-401': true}})
            axiosService.axios.get(`${endPoint.ControleDeAcesso.GetPerfilLegado}/${cod_descricao}`, {headers: {'X-No-Redirect-401': true}})
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    GetPerfilItens_Legado = (id) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            // axiosService.axios.get(`/usuarios/Profile/Legado/GetPerfilItens/${id}`)
            axiosService.axios.get(`${endPoint.ControleDeAcesso.GetPerfilItensLegado}/${id}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    CreatePerfil = (perfil) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            axiosService.axios.post(endPoint.ControleDeAcesso.CreatePerfil, perfil)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    EditPerfil = (perfil) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            axiosService.axios.put(endPoint.ControleDeAcesso.EditPerfil, perfil)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    RemovePerfil = (codperfil) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            axiosService.axios.delete(`${endPoint.ControleDeAcesso.RemovePerfil}/${codperfil}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    GetPermissaoAcessoUsuario = (cpf) => {
        return new Promise((resolve, reject) => {
            authService.setToken(authService.getToken());
            axiosService.axios.get(`${endPoint.ControleDeAcesso.GetPermissaoAcessoUsuario}/${cpf}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }
}

const controleAcessoService = new ControleAcessoService({ 
                                          profile
                                        });;

export default controleAcessoService;
