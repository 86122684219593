import axiosService from "../components/Utils/axios";

const endpoint = {
  Autenticacao: {
    SingIn: axiosService.local ? "/Usuarios/Usuarios/SignIn" : "/crm_usuarios/signin",
    SingInWithToken: axiosService.local ? "/Usuarios/Usuarios" : "/crm_usuarios",
  }, Atendimento: {
    GetTiposAtendimento: axiosService.local ? "/GetAllTiposAtendimento" : "/crm_atendimentos/getalltiposatendimento",
    GetPrioridades: axiosService.local ? "/GetAllPrioridades" : "/crm_atendimentos/getallprioridades",
    GetFilas: axiosService.local ? "/GetAllFilas" : "/crm_atendimentos/getallfilas",
    GetAtendimentos: axiosService.local ? "/GetAllAtendimentos" : "/crm_atendimentos/getallatendimentos",
    GetAtendimentosByStatus: axiosService.local ? "/GetAllAtendimentosByStatus" : "/crm_atendimentos/getallatendimentosbystatus",
    GetAtendimento: axiosService.local ? "/Atendimento" : "/crm_atendimentos",
    GetAnexoAtendimento: axiosService.local ? "/GetS3FileUrlFromName" : "/crm_atendimentos/gets3fileurlfromname",
    GetAnexoAtendimentoFinanceiro: axiosService.local ? "/GetS3FileAtendimentoFinanceiroUrlFromName" : "/crm_atendimentos/gets3fileatendimentofinanceirourlfromname",
    GetAnexosAcompanhamento: axiosService.local ? "/GetAnexosAcompanhamento" : "/crm_atendimentos/getanexosacompanhamento",
    GetAllHistorico: axiosService.local ? "/GetAllHistorico" : "/crm_atendimentos/getallhistorico",
    GetAllEmailAtendimento: axiosService.local ? "/GetAllEmailAtendimento" : "/crm_atendimentos/getallemailatendimento",
    CreateAtendimento: axiosService.local ? "/Atendimento" : "/crm_atendimentos",
    CreateAcompanhamento: axiosService.local ? "/CreateAcompanhamento" : "/crm_atendimentos/createacompanhamento",
    CreateAnexo: axiosService.local ? "/CreateAnexo" : "/crm_atendimentos/createanexo",
    SaveAnexoToS3Atendimento: axiosService.local ? "/SaveAnexoToS3Atendimento" : "/crm_atendimentos/saveanexotos3atendimento",
    InsertAnexoAtendimento: axiosService.local ? "/SaveAnexoToS3" : "/crm_atendimentos/saveanexotos3",
    EditAtendimento: axiosService.local ? "/Atendimento" : "/crm_atendimentos",
    EnviarEmailCliente: axiosService.local ? "/EnviarEmailCliente" : "/crm_atendimentos/enviaremailcliente",
    EnviarEmailClienteAcompanhamento: axiosService.local ? "/EnviarEmailClienteAcompanhamento" : "/crm_atendimentos/enviaremailclienteacompanhamento",
    DeleteAtendimento: axiosService.local ? "/DeleteAtendimento" : "/crm_atendimentos/deleteatendimento",
    DeleteAcompanhamento: axiosService.local ? "/DeleteAcompanhamento" : "/crm_atendimentos/deleteacompanhamento",
    CheckEmailInBlacklist: axiosService.local ? "/CheckEmailInBlacklist" : "/crm_atendimentos/checkemailinblacklist",
    AddEmailInBlacklist: axiosService.local ? "/AddEmailInBlacklist" : "/crm_atendimentos/addemailinblacklist",
    RemoveEmailInBlacklist: axiosService.local ? "/RemoveEmailInBlacklist" : "/crm_atendimentos/removeemailinblacklist",
    GetEmailsInBlacklist: axiosService.local ? "/EmailsInBlacklist" : "/crm_atendimentos/emailsinblacklist",
    GetContatosAbertos: axiosService.local ? "/GetContatosAbertos" : "/crm_atendimentos/getcontatosabertos",
    GetAtendimentosNaoRespondidos: axiosService.local ? "/GetAtendimentosNaoRespondidos" : "/crm_atendimentos/getatendimentosnaorespondidos",
    GetAtendimentosCounts: axiosService.local ? "Atendimento/GetAtendimentosCounts" : "/crm_atendimentos/getatendimentoscounts",
    GetEmailsContatos: axiosService.local ? "/GetEmailsContatos" : "/crm_atendimentos/getemailscontatos",
    AddEmailContato: axiosService.local ? "/AddEmailContato" : "/crm_atendimentos/addemailcontato",
    RemoveEmailContato: axiosService.local ? "/RemoveEmailContato" : "/crm_atendimentos/removeemailcontato",
    GetAtendimentosCountByStatus: axiosService.local ? "/GetAtendimentosCountByStatus" : "/crm_atendimentos/getatendimentoscountbystatus",
    AuthenticateEmail: axiosService.local ? "/Email/AuthenticateEmail" : "/email/authenticateemail",
    ReadAllEmails: axiosService.local ? "/Email/ReadAllEmails" : "/email/readallemails",
    GetCredentials: axiosService.local ? "/Email/GetCredentials" : "/email/getcredentials",
  }, ControleDeAcesso: {
    GetMenus: axiosService.local ? "/Usuarios/Usuarios/GetListMenu" : "/crm_usuarios/getlistmenu",
    GetMenusDefault: axiosService.local ? "/Usuarios/Usuarios/GetAllDefaultMenu" : "/crm_usuarios/getalldefaultmenu", // GetPerfis: axiosService.local ? '/GetAllProfile' : '/crm_usuarios/getallprofile',
    // GetPerfil: axiosService.local ? '/GetProfile' : '/crm_usuarios/getprofile',
    GetPermissaoAcessoUsuario: axiosService.local ? "/Usuarios/Usuarios/GetUserAccessPermissionList" : "/crm_usuarios/getuseraccesspermissionlist", // CreatePerfil: axiosService.local ? '/Profile' : '/crm_usuarios/profile',
    // EditPerfil: axiosService.local ? '/Profile' : '/crm_usuarios/profile',
    // RemovePerfil: axiosService.local ? '/Profile' : '/crm_usuarios/profile',
    profile: axiosService.local ? "/Usuarios/Profile" : "/crm_usuarios/profile",
    GetPerfilLegado: axiosService.local ? "/Usuarios/Profile/Legado" : "/crm_usuarios/profile/legado",
    GetPerfilItensLegado: axiosService.local ? "/Usuarios/Profile/Legado/GetPerfilItens" : "/crm_usuarios/profile/legado/getperfilitens",
  }, Financeiro: {
    GetGrupo: axiosService.local ? "/GetGrupo" : "/crm_financeiro/getgrupo",
    GetAnexosCliente: axiosService.local ? "/GetAnexosCliente" : "/crm_financeiro/getanexoscliente",
    GetAnexosGrupo: axiosService.local ? "/GetAnexosGrupo" : "/crm_financeiro/getanexosgrupo",
    GetGrupoAnexo: axiosService.local ? "/GetFinanceiroS3FileUrlFromName" : "/crm_financeiro/getfinanceiros3fileurlfromname",
    GetAllCustomersGroup: axiosService.local ? "/GetGridClientes" : "/crm_financeiro/getgridclientes",
    ListClientesGrupo: axiosService.local ? "/GetAllClientesGrupo" : "/crm_financeiro/getallclientesgrupo",
    ListAllClientes: axiosService.local ? "/GetAllClientes" : "/crm_financeiro/getallclientes",
    GetCliente: axiosService.local ? "/GetCliente" : "/crm_financeiro/getcliente",
    CreateGrupo: axiosService.local ? "/CreateGrupo" : "/crm_financeiro/creategrupo",
    CreateCliente: axiosService.local ? "/CreateCliente" : "/crm_financeiro/createcliente",
    CreateAnexoCliente: axiosService.local ? "/CreateAnexoCliente" : "/crm_financeiro/createanexocliente",
    CreateAnexo: axiosService.local ? "/CreateAnexoGrupo" : "/crm_financeiro/createanexogrupo",
    InsertAnexoCliente: axiosService.local ? "/SaveAnexoClienteToS3" : "/crm_financeiro/saveanexoclientetos3",
    InsertAnexoGrupo: axiosService.local ? "/SaveAnexoGrupoToS3" : "/crm_financeiro/saveanexogrupotos3",
    SetarClienteMaster: axiosService.local ? "/SetarClienteMaster" : "/crm_financeiro/setarclientemaster",
    EditGrupo: axiosService.local ? "/EditGrupo" : "/crm_financeiro/editgrupo",
    EditCliente: axiosService.local ? "/EditCliente" : "/crm_financeiro/editcliente",
    RemoverAnexoCliente: axiosService.local ? "/DeleteAnexoCliente" : "/crm_financeiro/deleteanexocliente",
    RemoverClienteEndereco: axiosService.local ? "/DeleteEnderecoCliente" : "/crm_financeiro/deleteenderecocliente",
    RemoverClienteResponsavel: axiosService.local ? "/DeleteResponsavelCliente" : "/crm_financeiro/deleteresponsavelcliente",
    RemoverClienteContato: axiosService.local ? "/DeleteContatoCliente" : "/crm_financeiro/deletecontatocliente",
    RemoverAnexoGrupo: axiosService.local ? "/DeleteAnexoGrupo" : "/crm_financeiro/deleteanexogrupo",
    motivoStatusCliente: axiosService.local ? "/Financeiro/MotivoStatusCliente" : "/crm_financeiro/motivostatuscliente",
    clienteStatus: axiosService.local ? "/Financeiro/ClienteStatus" : "/crm_financeiro/clientestatus",
  }, Fiscal: {
    GetSegmentos: axiosService.local ? "/Segmentos" : "/crm_fiscal/segmentos",
    GetNCMs: axiosService.local ? "/Ncm" : "/crm_fiscal/ncm",
    GetFamilyNCM: axiosService.local ? "/FamilyNCM" : "/crm_fiscal/familyncm",
    GetCest: axiosService.local ? "/CestItens" : "/crm_fiscal/cestitens",
    GetCestData: axiosService.local ? "/Cest" : "/crm_fiscal/cest", // Deixado CST legado, pois a tela esta utilizando neste formato, porem
    // a tela de config operacao utilizara da forma atualizada
    GetCst: axiosService.local ? "Fiscal/Cst" : "/crm_fiscal/cst",
    GetCstIcms: axiosService.local ? "/CstIcms" : "/crm_fiscal/csticms",
    GetCrt: axiosService.local ? "/Crt" : "/crm_fiscal/crt",
    FindCestItem: axiosService.local ? "/FindCest" : "/crm_fiscal/findcest",
    ListOrigemMercadoria: axiosService.local ? "/OrigemMercadoria" : "/crm_fiscal/origemmercadoria",
    ListAllCest: axiosService.local ? "/Cest" : "/crm_fiscal/cest",
    CreateCest: axiosService.local ? "/CreateCest" : "/crm_fiscal/createcest",
    CreateSegmento: axiosService.local ? "/CreateSegmentos" : "/crm_fiscal/createsegmentos",
    CreateSegmentoItem: axiosService.local ? "/CreateSegmentoItem" : "/crm_fiscal/createsegmentoitem",
    CreateCstIcms: axiosService.local ? "/CreateCstIcms" : "/crm_fiscal/createcsticms",
    CreateCstIcmsItem: axiosService.local ? "/CreateCstItem" : "/crm_fiscal/createcstitem",
    CreateCestItemNcm: axiosService.local ? "/CreateCestItemNcm" : "/crm_fiscal/createcestitemncm",
    CreateNCM: axiosService.local ? "/CreateNcm" : "/crm_fiscal/createncm",
    EditCestItemNcm: axiosService.local ? "/EditCestItemNcm" : "/crm_fiscal/editcestitemncm",
    EditSegmento: axiosService.local ? "/EditSegmento" : "/crm_fiscal/editsegmento",
    EditSegmentoItem: axiosService.local ? "/EditSegmentoItem" : "/crm_fiscal/editsegmentoitem",
    EditNCM: axiosService.local ? "/EditNcm" : "/crm_fiscal/editncm",
    EditNcmItem: axiosService.local ? "/EditNcmItem" : "/crm_fiscal/editncmitem",
    EditCstIcms: axiosService.local ? "/EditCstIcms" : "/crm_fiscal/editcsticms",
    EditCstIcmsItem: axiosService.local ? "/EditCstItem" : "/crm_fiscal/editcstitem",
    DeleteSegmentoItem: axiosService.local ? "/DeleteSegmentoItem" : "/crm_fiscal/deletesegmentoitem", // Deixado Cfop legado, pois a tela esta utilizando neste formato, porem
    // a tela de config operacao utilizara da forma atualizada
    GetCfop: axiosService.local ? "/Cfop" : "/crm_fiscal/cfop",
    CreateCfop: axiosService.local ? "/Cfop" : "/crm_fiscal/cfop",
    UpdateCfop: axiosService.local ? "/Cfop" : "/crm_fiscal/cfop",
    tipoProduto: axiosService.local ? "/Fiscal/TipoProduto" : "/crm_fiscal/tipoproduto",
    tipoVenda: axiosService.local ? "/Fiscal/TipoVenda" : "/crm_fiscal/tipovenda",
    atividadeEconomica: axiosService.local ? "/Fiscal/AtividadeEconomica" : "/crm_fiscal/atividadeeconomica",
    pisCofins: axiosService.local ? "/Fiscal/PisCofins" : "/crm_fiscal/piscofins",
    ufRegras: axiosService.local ? "/Fiscal/RegraEstado" : "/crm_fiscal/regraestado",
    uf: axiosService.local ? "/Fiscal/Uf" : "/crm_fiscal/uf",
    icmsInterestadual: axiosService.local ? "/Fiscal/IcmsInterestadual" : "/crm_fiscal/icmsinterestadual",
    meioPagamento: axiosService.local ? "/Fiscal/MeioPagamento" : "/crm_fiscal/meiopagamento",
    configNatOperacao: axiosService.local ? "/Fiscal/ConfigNatOperacao" : "/crm_fiscal/confignatoperacao",
    naturezaOperacao: axiosService.local ? "/Fiscal/NaturezaOperacao" : "/crm_fiscal/naturezaoperacao",
    cfop: axiosService.local ? "/Fiscal/Cfop" : "/crm_fiscal/cfop",
    cst: axiosService.local ? "/Fiscal/Cst" : "/crm_fiscal/cst",
    operacaoIcms: axiosService.local ? "/Fiscal/OperacaoICMS" : "/crm_fiscal/operacaoicms",
    motivosCancelamento: axiosService.local ? "/Fiscal/MotivosCancelamento" : "/crm_fiscal/motivoscancelamento",
    momentoCancelamento: axiosService.local ? "/Fiscal/MotivosCancelamento/MomentoCancelamento" : "/crm_fiscal/motivoscancelamento/momentocancelamento",
    bandeira: axiosService.local ? "/Fiscal/Bandeira" : "/crm_fiscal/bandeira",
    banco: axiosService.local ? "/Fiscal/Banco" : "/crm_fiscal/banco",
    anexoBanco: axiosService.local ? "/Fiscal/Banco/:id/Anexo" : "/crm_fiscal/banco/:id/anexo",
    cartoes: axiosService.local ? "/Fiscal/Bandeira/Cartoes" : "/crm_fiscal/bandeira/cartoes",
  }, Generico: {
    getCep: axiosService.local ? "/GetCep" : "/crm_genericos/getcep",
    getCnpj: axiosService.local ? "/GetCnpj" : "/crm_genericos/getcnpj",
    EnviarEmailRedefinirSenha: axiosService.local ? "/EnviarEmailRedefinirSenha" : "/crm_genericos/enviaremailredefinirsenha",
  }, Intranet: {
    GetPosts: axiosService.local ? "/Intranet/GetAllPost" : "/crm_intranet/getallpost",
    CreatePost: axiosService.local ? "/Intranet/Post" : "/crm_intranet/post",
  }, Usuario: {
    GetUsers: axiosService.local ? "/Usuarios/Usuarios/GetAllUsuarios" : "/crm_usuarios/getallusuarios",
    getUser: axiosService.local ? "/Usuarios/Usuarios/Usuarios" : "/crm_usuarios",
    CreateUsers: axiosService.local ? "/Usuarios/Usuarios/Usuarios" : "/crm_usuarios",
    EditUsers: axiosService.local ? "/Usuarios/Usuarios/Usuarios" : "/crm_usuarios",
    UpdatePassword: axiosService.local ? "/Usuarios/Usuarios/UpdatePassword" : "/crm_usuarios/updatepassword",
    UpdateAvatar: axiosService.local ? "/Usuarios/Usuarios/UpdateAvatar" : "/crm_usuarios/UpdateAvatar",
    RemoveUsers: axiosService.local ? "/Usuarios/Usuarios/Usuarios" : "/crm_usuarios",
    GerarCodigoRedefinirSenha: axiosService.local ? "/Usuarios/Usuarios/GerarCodigoRedefinirSenha" : "/crm_usuarios/gerarcodigoredefinirsenha",
    RedefinirSenha: axiosService.local ? "/Usuarios/Usuarios/edefinirSenha" : "/crm_usuarios/redefinirsenha",
  }, Controles: {
    GetDepartamentos: axiosService.local ? "/Departamentos" : "/crm_controles/departamentos",
    GetDepartamento: axiosService.local ? "/Departamentos" : "/crm_controles/departamentos",
    CreateDepartamento: axiosService.local ? "/Departamentos" : "/crm_controles/departamentos",
    UpdateDepartamento: axiosService.local ? "/Departamentos" : "/crm_controles/departamentos",
    GetDeptoFuncoes: axiosService.local ? "/DeptoFuncao/depto" : "/crm_controles/deptofuncao/depto", //GetDeptoFuncoesPorDepto: axiosService.local ? '/DeptoFuncao/depto' : '/crm_controles/deptofuncao/depto',
    GetDeptoFuncao: axiosService.local ? "/DeptoFuncao" : "/crm_controles/deptofuncao",
    GetFuncoes: axiosService.local ? "/Funcao" : "/crm_controles/funcao",
    GetFuncao: axiosService.local ? "/FuncaoById" : "/crm_controles/funcaobyid",
    ValidarDeptoFuncao: axiosService.local ? "/DeptoFuncao/Validar" : "/crm_controles/deptofuncao/validar",
    GetFuncionarios: axiosService.local ? "/Funcionarios" : "/crm_controles/funcionarios",
    CreateEmployees: axiosService.local ? "/Funcionarios" : "/crm_controles/funcionarios",
    GetProfile: axiosService.local ? "/Perfil" : "/crm_controles/perfil",
    GetFunctionsWithDepartment: axiosService.local ? "/Funcao" : "/crm_controles/funcao",
    GetFuncionariosByDeptoFuncao: axiosService.local ? "/GetFuncionarioDeptoFuncao" : "/crm_controles/getfuncionariodeptofuncao",
    GetPerson: axiosService.local ? "/Pessoa" : "/crm_controles/pessoa",
    GetFuncionarioPessoa: axiosService.local ? "/FuncionarioPessoa" : "/crm_controles/funcionariopessoa",
    UpdateEmployees: axiosService.local ? "/Funcionarios" : "/crm_controles/funcionarios",
    CreateAnexoFuncionario: axiosService.local ? "/CreateAnexoFuncionario" : "/crm_controles/createanexofuncionario",
    SaveAttachmentToS3Employees: axiosService.local ? "/SaveAnexoToS3Funcionario" : "/crm_controles/saveanexotos3funcionario",
    GetEmployeesAttachment: axiosService.local ? "/FuncionarioAnexo" : "/crm_controles/funcionarioanexo",
    GetEmployeeCompany: axiosService.local ? "/FuncionarioEmpresa" : "/crm_controles/funcionarioempresa",
    GetAnexoFuncionario: axiosService.local ? "/GetS3FileFuncionarioUrlFromName" : "/crm_controles/gets3filefuncionariourlfromname",
    funcao: axiosService.local ? "/Funcao" : "/crm_controles/funcao",
  },
};

export default endpoint;
