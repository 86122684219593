import React from "react";
import {Outlet} from "react-router";
import ModuleContainer from "../components/ModuleContainer";

function ModuloControles() {
  return (
    <ModuleContainer>
      <Outlet />
    </ModuleContainer>
  );
}

export default ModuloControles;
