import { ExpandMore, ExpandLess, ChevronRight } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

const RenderMenuItemAll = (classes, events, istouch, subMenuOpen) => {
  return (
    <div className={`${classes.menuItem} ${classes.menuItemAll}`} {...events.menuItemAll}>
      <MenuIcon fontSize="small" />
      <span>Todos</span>
      {!istouch || !subMenuOpen ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
    </div>
  );
};

const RenderMenuItems = (classes, events, state) => {
  return state.menu.map(({ descricao, path, permission }, index) => (
    <div
      key={index}
      className={clsx(classes.menuItem, `menu-${path}`, !permission.access && classes.menuItemDisable)}
      {...events.menuItem(path)}>
      <span>{descricao}</span>
    </div>
  ));
};

const RenderSubMenu = (classes, events, state) => {
  return (
    <div className={classes.subMenu}>
      {state.menu.map(({ descricao, path, permission }, index) => (
        <div
          key={index}
          className={clsx(classes.subMenuItem, !permission.access && classes.disable)}
          {...events.subMenuItem(path)}
          disable={permission.access}>
          <span className={classes.ellipsis}>{descricao}</span>
          <ChevronRight />
        </div>
      ))}
    </div>
  );
};

const RenderFinalMenu = (classes, events, state, finalMenuOpen, getLeft, menuModel) => {
  return state.menu.map(({ path, children }, index) => {
    return (
      children?.length && (
        <div
          key={index}
          className={clsx(classes.finalMenu, `final-menu-${path}`, menuModel === 2 && finalMenuOpen === path && classes.opacityVisible)}
          style={{
            left: getLeft(path),
          }}
          {...events.finalMenu}>
          <div className={clsx(classes.categoryModuleContainer, menuModel === 1 && finalMenuOpen === path && classes.opacityVisible)}>
            {children?.length &&
              children.map((item) => {
                const { descricao, children } = item;

                return children?.length ? (
                  <div className={clsx(classes.finalMenuItem)}>
                    <span className={clsx(classes.category)}>{descricao}</span>
                    <div className={classes.categoryChildContainer}>
                      {children.map((item) => RenderFinalMenuItem(classes, events, item))}
                    </div>
                  </div>
                ) : (
                  RenderFinalMenuItem(classes, events, item)
                );
              })}
          </div>
        </div>
      )
    );
  });
};

const RenderFinalMenuItem = (classes, events, { descricao, fullPath, permission }) => {
  return (
    <>
      <NavLink
        to={fullPath}
        className={clsx(classes.finalMenuItem, permission.access ? classes.finalMenuItemLink : classes.disable)}
        {...events.finalMenuItem(permission)}>
        <span>{descricao}</span>
      </NavLink>
    </>
  );
};

export { RenderMenuItemAll, RenderMenuItems, RenderSubMenu, RenderFinalMenu };
