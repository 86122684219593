import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";

import AuthProvider from "./components/AuthProvider";
import theme from "./theme";
import store from "./store";

import AppRoutes from "./routes/AppRoutes";
import { PerfilAcessoProvider } from "./contexts/PerfilAcessoContext";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AuthProvider>
              <PerfilAcessoProvider>
                <AppRoutes />
              </PerfilAcessoProvider>
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
