import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from "@material-ui/core/FormHelperText";

import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

import VoltarLogin from "./VoltarLogin";

import userService from "../../services/userService";
import genericosService from "../../services/genericosService";
import {htmlRedefinirSenha, htmlEmailNaoEncontrado} from "./lib.js";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh"        
    },
    image: {
        backgroundImage: 'url(/images/artelogin.png)',
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0, 4),
    },
    avatar: {
        background: "#2a6ea0",
        marginBottom: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),       
    },
    img:{
        maxHeight: 60,
    },
    Typography:{        
        marginTop: theme.spacing(2),
    },
    selectError : {
        color: "#f44336"
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.futurasistemas.com.br/">
                Futura Sistemas
         </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
      fontWeight: 600,
      color: theme.palette.getContrastText('#2c699d'),
      backgroundColor: '#2c699d',
      '&:hover': {
        backgroundColor: lightBlue[900],
      },
    },    
  }))(Button);
  
function EsqueceuSenha(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState();
    const dispacth = useDispatch();
    const { setRedefinirSenha, setEsqueceuSenha, setEmailRedefinir } = (props);
    const [errors, setErrors] = useState({});

    const validate = () => {
        let temp = { ...errors }

        temp.email = email && email.length > 0 && email.includes("@") ? "" : "E-mail inválido."

        setErrors({
            ...temp
        })

        return Object.values(temp).every(x => x === "")
    }

    return (
        <Grid container className={classes.root}>
            <Grid item md={3}></Grid>

            <Grid item md={6}>
                <Box display="flex" flexDirection="column" alignItems="center" m={8}>
                    <img src="/images/Logo-futura-CRM.png" alt="logo" className={classes.img}/> 
                    <h1>Esqueceu a senha</h1>
                    <br/>
                    <p>Não se preocupe! Informe seu e-mail de cadastro e clique em "Redefinir senha"</p>
                    <br/>
                    <form className={classes.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            onKeyPress={(key) => {
                                if(key.code === 'Enter' || key.code === 'NumpadEnter'){
                                    let emailValido = validate();

                                    if (emailValido) {
                                        userService.GerarCodigoRedefinirSenha(email)
                                        .then(() => {
                                            let solicitacaoEmail = {
                                                destinario: email,
                                                assunto : "Futura Portal-CRM | Redefinição de Senha!",
                                                corpoMensagem : htmlRedefinirSenha
                                            };
    
                                            genericosService.EnviarEmailRedefinirSenha(solicitacaoEmail);
                                        })
                                        .catch (err => {
                                            let solicitacaoEmail = {
                                                destinario: email,
                                                assunto : "Futura Portal-CRM | E-mail não encontrado!",
                                                corpoMensagem : htmlEmailNaoEncontrado
                                            };
    
                                            genericosService.EnviarEmailRedefinirSenha(solicitacaoEmail);
                                        });
                                        setEmailRedefinir(email);
                                        setRedefinirSenha(true);
                                        setEsqueceuSenha(false);
                                    } 
                                }
                              }}
                            error={errors.email}
                        />
                        {
                            errors.email &&
                            <FormHelperText className={classes.selectError}>{errors.email ? errors.email : "" }</FormHelperText>
                        }
                                         
                        <ColorButton                            
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                let emailValido = validate();

                                if (emailValido) {
                                    userService.GerarCodigoRedefinirSenha(email)
                                    .then(() => {
                                        let solicitacaoEmail = {
                                            destinario: email,
                                            assunto : "Futura Portal-CRM | Redefinição de Senha!",
                                            corpoMensagem : htmlRedefinirSenha
                                        };

                                        genericosService.EnviarEmailRedefinirSenha(solicitacaoEmail);
                                    })
                                    .catch (err => {
                                        let solicitacaoEmail = {
                                            destinario: email,
                                            assunto : "Futura Portal-CRM | E-mail não encontrado!",
                                            corpoMensagem : htmlEmailNaoEncontrado
                                        };

                                        genericosService.EnviarEmailRedefinirSenha(solicitacaoEmail);
                                    });
                                    setEmailRedefinir(email);
                                    setRedefinirSenha(true);
                                    setEsqueceuSenha(false);
                                }
                            }}>                                
                            Redefinir senha
                        </ColorButton>

                        {
                            errorMessage &&
                            <FormHelperText error>
                                {
                                    errorMessage
                                }
                            </FormHelperText>
                        }
                        <Box mt={35}>
                            <VoltarLogin />
                        </Box>
                    </form>
                </Box>
            </Grid>
            <Grid item md={3}></Grid>
        </Grid>
    )
}

export default EsqueceuSenha;