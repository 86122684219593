import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh"        
    },
    image: {
        backgroundImage: 'url(/images/artelogin.png)',
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0, 4),
    },
    avatar: {
        background: "#2a6ea0",
        marginBottom: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),       
    },
    img:{
        maxHeight: 60,
    },
    Typography:{        
        marginTop: theme.spacing(2),
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.futurasistemas.com.br/">
                Futura Sistemas
         </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
      fontWeight: 600,
      color: theme.palette.getContrastText('#2c699d'),
      backgroundColor: '#2c699d',
      '&:hover': {
        backgroundColor: lightBlue[900],
      },
    },    
  }))(Button);
  
function RedefinirSucesso(props) {
    const classes = useStyles();
    const {setRedefinirSenhaSucesso} = (props)

    return (
        <Grid container className={classes.root}>
            <Grid item md={3}></Grid>

            <Grid item md={6}>
                <Box display="flex" flexDirection="column" alignItems="center" m={8}>
                    <img src="/images/Logo-futura-CRM.png" alt="logo" className={classes.img}/> 
                    <h1>Redefinição de Senha efetuada com sucesso!</h1>
                    <br/>
                    
                    <ColorButton                            
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            setRedefinirSenhaSucesso(false);
                        }}
                        >                                
                        Fazer Login
                    </ColorButton>
                </Box>
            </Grid>
            <Grid item md={3}></Grid>
        </Grid>
    )
}

export default RedefinirSucesso;