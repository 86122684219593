import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from "@material-ui/core/FormHelperText";

import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

import { signIn } from '../../store/actions/accountActions';
import EsqueceuSenha from "./EsqueceuSenha";
import RedefinirSenha from "./RedefinirSenha";
import RedefinirSucesso from "./RedefinirSucesso";

import instance from "../../components/Utils/axios";
import loginGlobal from "../../components/Utils/loginGlobal";
import { usePerfilAcessoContext } from "../../contexts/PerfilAcessoContext";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh"
    },
    image: {
        backgroundImage: 'url(/images/artelogin.png)',
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0, 4),
    },
    avatar: {
        background: "#2a6ea0",
        marginBottom: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
    img:{
        maxHeight: 60,
    },
    Typography:{
        marginTop: theme.spacing(2),
    },
    link : {
        cursor : "pointer"
    }
}));

function Copyright() {
    // const versao = `v-1.0.57`;
    const versao = loginGlobal.versaoSistema;
    const ambiente = `${ instance.local ? " - LOCAL" : ( instance.hml ? ' - AMBIENTE HOMOLOGAÇÃO' : '') }`;

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.futurasistemas.com.br/">
                Futura Sistemas
         </Link>{' '}
            {new Date().getFullYear()}
            {'. '}
            {`(${versao}${ambiente})`}
        </Typography>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
      fontWeight: 600,
      color: theme.palette.getContrastText('#2c699d'),
      backgroundColor: '#2c699d',
      '&:hover': {
        backgroundColor: lightBlue[900],
      },
    },
  }))(Button);

function Login() {
    const classes = useStyles();
    const [esqueceuSenha, setEsqueceuSenha] = useState(false);
    const [redefinirSenha, setRedefinirSenha] = useState(false);
    const [redefinirSenhaSucesso, setRedefinirSenhaSucesso] = useState(false);

    const [emailCpf, setEmailCPF] = useState('');
    const [emailRedefinir, setEmailRedefinir] = useState('');
    const [senha, setSenha] = useState('');

    const [errorMessage, setErrorMessage] = useState();
    const dispacth = useDispatch();
    const [state, setState] = usePerfilAcessoContext();

    async function handleSignIn() {
        try {
          setState({ ...state, waitingPerfil: true })
          await dispacth(signIn(emailCpf, senha));
        } catch (error) {
            setErrorMessage(error.response.data.message);
        }
    }

    return (
        <>
        {
            (!esqueceuSenha || esqueceuSenha === "false") && (!redefinirSenha || redefinirSenha === "false") &&
            (!redefinirSenhaSucesso || redefinirSenhaSucesso === "false") &&
            <Grid container className={classes.root}>
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                    <Box display="flex" flexDirection="column" alignItems="center" m={8}>
                        <img src="/images/Logo-futura-CRM.png" alt="logo" className={classes.img}/>
                        <form className={classes.form}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="emailCpf"
                                label="E-mail ou CPF"
                                name="emailCpf"
                                autoComplete="emailCpf"
                                autoFocus
                                value={emailCpf}
                                onChange={(event) => setEmailCPF(event.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={senha}
                                onChange={(event) => setSenha(event.target.value)}
                                onKeyPress={(key) => {
                                    if(key.code === 'Enter' || key.code === 'NumpadEnter'){
                                        handleSignIn();
                                    }
                                  }}
                            />
                            <ColorButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSignIn} >
                                Login
                            </ColorButton>
                            {
                                errorMessage &&
                                <FormHelperText error>
                                    {
                                        errorMessage
                                    }
                                </FormHelperText>
                            }
                            <Grid container>
                                <Grid item>
                                    <Link className={classes.link} variant="body2" onClick={() => setEsqueceuSenha(true)}>
                                        Esqueceu a senha?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </Box>
                </Grid>
                <Grid item md={3}></Grid>
            </Grid>
        }
        {
            esqueceuSenha &&
            <EsqueceuSenha setEsqueceuSenha ={setEsqueceuSenha} setRedefinirSenha={setRedefinirSenha} setEmailRedefinir={setEmailRedefinir}/>
        }
        {
            redefinirSenha &&
            <RedefinirSenha setEsqueceuSenha ={setEsqueceuSenha} setRedefinirSenha={setRedefinirSenha} setRedefinirSenhaSucesso = {setRedefinirSenhaSucesso} emailRedefinir={emailRedefinir} />
        }
        {
            redefinirSenhaSucesso &&
            <RedefinirSucesso setRedefinirSenhaSucesso = {setRedefinirSenhaSucesso} />
        }
        </>
    )
}

export default Login;
