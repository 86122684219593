import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router";
import { usePerfilAcessoContext } from "../contexts/PerfilAcessoContext";
import guestRoutes from "./guestRoutes";
import Protected from "./accessControl/Protected";
import RouteComponents from "./RouteComponents";

const Routes = () => {
  const account = useSelector(({ account }) => account);
  const isAuthenticated = Boolean(account?.usuario);
  const [state] = usePerfilAcessoContext();

  const routes = !isAuthenticated
    ? [
        ...guestRoutes,
        {
          path: "*",
          element: <Navigate replace to="/login" />,
        },
      ]
    : [
        ...guestRoutes,
        {
          path: "/",
          element: (
            <RouteComponents component="Home" />
          ),
          children: [
            ...state.routes,
            {
              path: "profile",
              element: (
                <Protected route={{onlyLogged: true}}>
                  <RouteComponents component="Profile" />
                </Protected>
              ),
            },
            {
              path: "intranet/:id",
              element: (
                <Protected route={{onlyLogged: true}}>
                  <RouteComponents component="Intranet" />
                </Protected>
              ),
            },
            {
              path: "colaborador-perfil/:id",
              element: (
                <Protected route={{onlyLogged: true}}>
                  <RouteComponents component="ColabPerfil" />
                </Protected>
              ),
            },
            { path: "*", element: <h1>Página não encontrada.</h1> },
          ],
        },
        { path: "*", element: <h1>Página não encontrada.</h1> },
      ];

  return useRoutes(routes);
};

export default Routes;
