import { colors } from '@material-ui/core';

const palette = {
    primary: {
        dark: colors.lightBlue['A900'],
        main: "#2a6ea0",
        light: colors.lightBlue['A100']
    },
    secondary: {
        dark: colors.orange['A700'],
        main: colors.orange['A700'],
        light: colors.orange['A200']
    },
    companySecondary: {
        dark: colors.orange['A700'],
        main: colors.orange['A700'],
        light: colors.orange['A200']
    }

}

export default palette;