import { makeStyles } from "@material-ui/styles";

const height = "60vh";
const active = {
  backgroundColor: "#EEE",
  fontWeight: "bold",
  "& *": {
    color: "#000",
  },
};
const width = "160px";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nav: {
    backgroundColor: "#455068",
    color: theme.palette.common.white,
    userSelect: "none",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    padding: "18px 24px",
    textDecoration: "none",
    color: "#DDD",
    "&:hover": {
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  menuItemAll: {
    "&:hover": {
      cursor: "default",
    },
    "& *": {
      margin: "0 2px",
    },
  },
  subMenuContainer: {
    display: "flex",
    position: "absolute",
    zIndex: 9999,
    width: "100%",
    height,
    boxShadow: "0 2px 10px #0003",
    backgroundColor: "#FFF",
  },
  subMenu: {
    width,
    background: "#FFF",
    height: height,
    position: "relative",
    zIndex: 9999,
  },
  subMenuItem: {
    display: "flex",
    alignItems: "center",
    padding: "8px 8px 8px 24px",
    textDecoration: "none",
    justifyContent: "space-between",
    color: "#444",
    "&:hover": {
      color: "#000",
      backgroundColor: "#DDD",
      cursor: "default",
    },
  },
  finalMenu: {
    position: "absolute",
    top: 0,
    left: width,
    width: `calc(100vw - ${width})`,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height,
    alignContent: "flex-start",
  },
  finalMenuItem: {
    padding: "0 24px",
    color: "#444",
    margin: "4px 0",
  },
  finalMenuItemLink: {
    textDecoration: "none",
    "& span:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: "#4449",
    },
  },
  category: {
    fontWeight: "bold",
  },
  categoryModuleContainer: {
    paddingLeft: "18px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height,
    opacity: 0,
    animation: "$effectExit .3s ease-in-out",
  },
  categoryChildContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
    "& a": {
      paddingLeft: "12px",
    },
  },
  active,
  hidden: {
    display: "none",
  },
  "@keyframes effect": {
    "0%": {
      opacity: 0,
      transform: "translateX(-200%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  "@keyframes effectExit": {
    "0%": {
      opacity: 1,
      transform: "translateX(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateX(-200%)",
    },
  },
  opacityVisible: {
    zIndex: 1,
    opacity: 1,
    animation: "$effect .3s  ease-in-out",
  },
  disable: {
    color: "#CCC",
    textDecoration: "none",
    cursor: "not-allowed",
    "&:hover": {
      color: "#CCC",
      textDecoration: "none",
      cursor: "not-allowed",
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "block",
  },
}));

export default useStyles;
