import endPoint from "../../utils/endpoints";
import FrontRequest from "../../utils/api/FrontRequest";
import { sendRequest } from "../../utils/api/sendRequest";

const profile = FrontRequest(endPoint.ControleDeAcesso.profile);

profile.submit = (poProfile) => {
    const codperfil = poProfile.codperfil;

    return sendRequest({
      method: codperfil ? 'PUT' : 'POST',
      url: `${endPoint.ControleDeAcesso.profile}`,
      data: poProfile
    });
  }

export default profile;