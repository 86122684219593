import { makeStyles } from "@material-ui/styles";

const height = "60vh";
const active = {
  backgroundColor: "#EEE",
  fontWeight: "bold",
  "& *": {
    color: "#000",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nav: {
    backgroundColor: "#455068",
    color: theme.palette.common.white,
    userSelect: "none",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    padding: "18px 24px",
    textDecoration: "none",
    color: "#DDD",
    "&:hover": {
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  menuItemAll: {
    "&:hover": {
      cursor: "default",
    },
    "& *": {
      margin: "0 2px",
    },
  },
  subMenuContainer: {
    display: "flex",
    position: "absolute",
    zIndex: 9999,
    width: "100%",
    maxHeight: height,
  },
  subMenu: {
    display: "none",
  },
  subMenuItem: {
    display: "none",
  },
  finalMenu: {
    textOrientation: "upright",
    position: "absolute",
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxHeight: height,
    backgroundColor: "#FFF",
    borderRadius: "4px",
    boxShadow: "1px 2px 4px #0002",
    top: "60px",
    opacity: 0,
  },
  finalMenuItem: {
    writingMode: "horizontal-tb",
    padding: "0 24px",
    color: "#444",
    margin: "4px 0",
  },
  finalMenuItemLink: {
    textDecoration: "none",
    "& span:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: "#4449",
    },
  },
  category: {
    fontWeight: "bold",
  },
  categoryModuleContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxHeight: height,
    writingMode: "vertical-lr",
  },
  categoryChildContainer: {
    writingMode: "horizontal-tb",
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
    "& a": {
      paddingLeft: "12px",
    },
  },
  active,
  hidden: {
    display: "none",
  },
  opacityVisible: {
    zIndex: 1,
    opacity: 1,
    top: "0px",
    animation: "$effect .2s  ease-in-out",
  },
  "@keyframes effect": {
    "0%": {
      opacity: 0,
      transform: "translateY(60px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  disable: {
    color: "#CCC",
    textDecoration: "none",
    cursor: "not-allowed",
  },
  menuItemDisable: {
    color: "#888",
    cursor: "not-allowed",
    "&:hover": {
      color: "#888",
      cursor: "not-allowed",
    }
  }
}));

export default useStyles;
