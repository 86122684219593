import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: 0,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        marginTop: 100,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        maxHeight: 60,
    },
    mensagem: {
        fontSize: 36,
        fontWeight: 400,
        color: theme.palette.common.black,
    },
    btnVoltarLogin: {
        height: 35,
        width: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    }
}));

const SessaoExpirou = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleClickParaLogin = () => {
        navigate('/login');
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.logo}>
                    <img src="/images/Logo-futura-CRM.png" alt="logo" className={classes.img} />
                </div>
                <div className={classes.mensagem}>
                    Sua sessão expirou!
                </div>
                <div className={classes.btnVoltarLogin} onClick={() => handleClickParaLogin()}>
                    Faça login novamente!
                </div>
            </div>
        </div>
    )
}

export default SessaoExpirou;
