import React from "react";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import {NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import LogoHeader from "../../../components/LogoHeader";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  titulo: {
    color: theme.palette.common.black,
  },
  logoCRM: {
    height: 50,
    width: 150,
  },
}));

function Header() {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <ToolBar>
        <Typography component="span" variant="h4" noWrap className={classes.titulo}>
          <NavLink to="/">
            <img src="/images/Logo-Portal---CRM-163x54.png" alt="" className={classes.logoCRM} />
          </NavLink>
        </Typography>
        <LogoHeader />
      </ToolBar>
    </AppBar>
  );
}

export default Header;
