import axiosService from "../components/Utils/axios";
import endPoint from "../utils/endpoints";
import authService from './authService';

class GenericosService {
    getCep = (cep) => {
        return new Promise((resolve, reject) => {
            axiosService.axios.get(`${endPoint.Generico.getCep}/${cep}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    getCnpj = (cnpj) => {
        return new Promise((resolve, reject) => {
            axiosService.axios.get(`${endPoint.Generico.getCep}/${cnpj}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    EnviarEmailRedefinirSenha = (solicitacaoEmail) => {
        return new Promise((resolve, reject) => {
            axiosService.axios.post(`${endPoint.Generico.EnviarEmailRedefinirSenha}`,solicitacaoEmail)
            //axios.post(`/EnviarEmailRedefinirSenha`, solicitacaoEmail)
            //axios.get(`/crm_genericos/EnviarEmailRedefinirSenha/${solicitacaoEmail}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        }); 
    }
}

const genericosService = new GenericosService();

export default genericosService;