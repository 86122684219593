const utils = ({ subMenuOpen, setSubMenuOpen, finalMenuOpen, setFinalMenuOpen, menuModel, deviceType }) => {
  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };
  const openSubMenu = () => {
    setSubMenuOpen(true);
  };
  const closeSubMenu = () => {
    setSubMenuOpen(false);
  };

  const handleFinalMenu = (path) => {
    setFinalMenuOpen(path);
  };

  const touchEvents = {
    rootContainer: {
      onMouseOver: (e) => {
        if (e.target.classList.contains("container-nav-menu")) {
          setSubMenuOpen(false);
        }
      },
      onMouseLeave: () => {
        setSubMenuOpen(false);
      },
    },
    menuItemAll: { onClick: toggleSubMenu },
    menuItem: (path) => ({
      onClick: () => {
        handleFinalMenu(path);
        openSubMenu();
      },
    }),
    subMenuContainer: {},
    subMenuItem: (path) => ({ onClick: () => handleFinalMenu(path) }),
  };

  const defaultEvents = {
    rootContainer:
      menuModel === 2
        ? {
            onMouseOver: (e) => {
              if (e.target.classList.contains("container-nav-menu")) {
                setFinalMenuOpen(null);
              }
            },
            onMouseLeave: () => {
              setFinalMenuOpen(null);
            },
          }
        : {},
    menuItemAll: {
      onMouseOver: openSubMenu,
      onMouseLeave: closeSubMenu,
    },
    menuItem: (path) => ({
      onMouseOver: () => {
        if (subMenuOpen !== true) {
          openSubMenu();
        }
        if (finalMenuOpen !== path) {
          handleFinalMenu(path);
        }
      },
    }),
    subMenuContainer: {
      onMouseOver: openSubMenu,
      onMouseLeave: closeSubMenu,
    },
    subMenuItem: (path) => ({ onMouseOver: () => handleFinalMenu(path) }),
  };

  const eventsTypeDevice = {
    touch: touchEvents,
    default: defaultEvents,
  };

  const events = {
    ...eventsTypeDevice[deviceType],
    ...{
      finalMenuItem: (permission) =>
        menuModel === 2
          ? {
              onClick: (e) => {
                if (!permission.access) {
                  e.preventDefault();
                } else {
                  setFinalMenuOpen(null);
                  closeSubMenu();
                }
              },
            }
          : {
              onClick: (e) => (!permission.access ? e.preventDefault() : toggleSubMenu()),
            },
      finalMenu: {
        onMouseLeave: () => {
          setFinalMenuOpen(null);
          if (menuModel === 2) {
            closeSubMenu();
          }
        },
      },
    },
  };
  return { events };
};

export default utils;
