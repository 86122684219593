import axiosService from "../components/Utils/axios";
import endPoint from "../utils/endpoints";

class AuthService {


    SingIn = (emailCpf, senha) => {
        return new Promise((resolve, reject) => {
            axiosService.axios.post(endPoint.Autenticacao.SingIn, { emailCpf, senha })
                .then((response) => {
                    if (response.data) {
                        this.setToken(response.data.token);
                        this.setUserName(emailCpf);
                        resolve(response.data);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    SingInWithToken = () => {
        return new Promise((resolve, reject) => {

            const emailCpf = this.getUserName();

            this.setToken(this.getToken());
            axiosService.axios.get(`${endPoint.Autenticacao.SingInWithToken}/${emailCpf}`, {headers: {'X-No-Redirect-401': true}})
            //axios.get(`/Usuarios/${emailCpf}`)
            //axios.get(`/crm_usuarios/${emailCpf}`)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.error);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    SingOut = () => {
        this.removeToken();
        this.removeUserName();
    }

    setToken = (token) => {

        if (token) {
            localStorage.setItem('accessToken', token);
            axiosService.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        } else {
            localStorage.removeItem('token');
            delete axiosService.axios.defaults.headers.common.Authorization;
        }
    };

    setUserName = (username) => {
        localStorage.setItem('username', username);
    };

    getUserName = () => localStorage.getItem('username');

    getToken = () => localStorage.getItem('accessToken');

    removeToken = () => localStorage.removeItem('accessToken');

    removeUserName = () => localStorage.removeItem('username');

    isAuthenticated = () => !!this.getToken();
}

const authService = new AuthService();

export default authService;
