const Query = class {
  constructor(list) {
    this.list = list || {
      items: [],
    };

    const { linkOperator } = this.list;
    if (linkOperator) {
      const items = this.list.items.map((item, index) => (index ? { linkOperator: linkOperator, ...item } : item));
      this.list = { items };
    }
  }
  clean() {
    this.list = { items: [] };
  }
  where(columnField, operatorValue, value, typeField) {
    this.add({ columnField, operatorValue, value, typeField }, 'where');
    return this;
  }
  and(columnField, operatorValue, value, typeField) {
    this.add({ columnField, operatorValue, value, typeField }, 'and');
    return this;
  }
  or(columnField, operatorValue, value, typeField) {
    this.add({ columnField, operatorValue, value, typeField }, 'or');
    return this;
  }
  andGroup(func) {
    const query = new Query({
      items: [],
    });
    func(query);
    this.add(query.getObject().data, 'and');
    return this;
  }
  orGroup(func) {
    const query = new Query({
      items: [],
    });
    func(query);
    this.add(query.getObject().data, 'or');
    return this;
  }
  group(columnField, operatorValue, value, typeField) {
    this.add({ columnField, operatorValue, value, typeField }, null);
    return this;
  }
  _(columnField, operatorValue, value, typeField) {
    this.add({ columnField, operatorValue, value, typeField }, null);
    return this;
  }
  add(params, linkOperator) {
    const { columnField, operatorValue, value, typeField } = params;
    let { items } = this.list;
    linkOperator = linkOperator ? { linkOperator } : {};
    if (params.items) {
      items = [...items, { ...linkOperator, ...params }];
    } else {
      items = [...items, { ...linkOperator, columnField, operatorValue, value, typeField }];
    }

    this.list = this.list = { ...this.list, items };
    return this;
  }
  getObject() {
    return {
      data: this.list,
    };
  }
  limit() { }
  offset() { }
  orderBy() { }
  groupBy() { }
};

const query = new Query();

export default query;