import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from "@material-ui/core/FormHelperText";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

import VoltarLogin from "./VoltarLogin";

import genericosService from "../../services/genericosService";
import userService from "../../services/userService";
import { AlignCenter } from "react-feather";
import {htmlRedefinirSenha} from "./lib.js"

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh"        
    },
    image: {
        backgroundImage: 'url(/images/artelogin.png)',
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0, 4),
    },
    avatar: {
        background: "#2a6ea0",
        marginBottom: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),       
    },
    buttonCodigoNovo: {
        margin: theme.spacing(2, 2, 2),       
    },
    img:{
        maxHeight: 60,
    },
    Typography:{        
        marginTop: theme.spacing(2),
    },
    digitos: {
        width: 60,
        marginRight: 5,
        '& .MuiInputBase-input' : {
            fontSize: "4.8rem",
            textAlign: 'center'
        },
    },
    selectError : {
        color: "#f44336"
    },   
    digitoError : {
        textAlign : "center"
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.futurasistemas.com.br/">
                Futura Sistemas
         </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
      fontWeight: 600,
      color: theme.palette.getContrastText('#2c699d'),
      backgroundColor: '#2c699d',
      '&:hover': {
        backgroundColor: lightBlue[900],
      },
    }, 
  }))(Button);
  
function RedefinirSenha(props) {
    const classes = useStyles();

    const [digito1, setDigito1] = useState('');
    const [digito2, setDigito2] = useState('');
    const [digito3, setDigito3] = useState('');
    const [digito4, setDigito4] = useState('');
    const [digito5, setDigito5] = useState('');
    const [digito6, setDigito6] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [errors, setErrors] = useState({});
    const [enviarNovoCodigo, setEnviarNovoCodigo] = useState(false);

    const { setRedefinirSenha, setEsqueceuSenha, setRedefinirSenhaSucesso, emailRedefinir } = (props);

    const d1 = useRef(null);
    const d2 = useRef(null);
    const d3 = useRef(null);
    const d4 = useRef(null);
    const d5 = useRef(null);
    const d6 = useRef(null);
    const ns = useRef(null);

    const validate = () => {
        let codigo = digito1.trim() + digito2.trim() + digito3.trim() + digito4.trim() + digito5.trim() + digito6.trim();

        let temp = { ...errors }

        temp.codigoVerificacao = codigo.length == 6 ? "" : "Código de verificação inválido."

        temp.novaSenha = novaSenha && novaSenha.length > 0 ? "" : "Senha é obrigatória."

        setErrors({
            ...temp
        })

        return Object.values(temp).every(x => x === "")
    }

    const handleRedefinirSenhaClick = async () => {
        let codigo = digito1.trim() + digito2.trim() + digito3.trim() + digito4.trim() + digito5.trim() + digito6.trim();

        let valido = validate();

        if(valido) {

            try 
            {
                await userService.RedefinirSenha(emailRedefinir, codigo, novaSenha)
                .then(() => {
                    setRedefinirSenha(false);
                    setEsqueceuSenha(false);
                    setRedefinirSenhaSucesso(true);
                }  );
            }
            catch (error) {
                let temp = { ...errors }

                temp.codigoVerificacao = error.response && error.response.data.message || error.message;

                if(temp.codigoVerificacao.includes("expirado"))
                    setEnviarNovoCodigo(true);

                setErrors({
                    ...temp
                });

                setDigito1("");
                setDigito2("");
                setDigito3("");
                setDigito4("");
                setDigito5("");
                setDigito6("");

                d1.current.focus();
            }
        }
    }

    return (
        <Grid container className={classes.root}>
            <Grid item md={3}></Grid>

            <Grid item md={6}>
                <Box display="flex" flexDirection="column" alignItems="center" m={8}>
                    <img src="/images/Logo-futura-CRM.png" alt="logo" className={classes.img}/> 
                    <h1>Código de Verificação</h1>
                    <br/>
                    <p>Digite o código recebido abaixo para cadastrar uma nova senha.</p>
                    <br/>
                    <form className={classes.form}>
                        <div style={{textAlign: "center"}}>
                            <div style={{display: "inline-flex"}}>
                                <TextField
                                    className={classes.digitos}
                                    id="d1"
                                    name="d1"
                                    autoFocus
                                    inputProps={{
                                        ref: d1,
                                        maxLength: 1
                                    }}
                                    size="normal"
                                    value={digito1}
                                    onChange={(event) => {
                                        setDigito1(event.target.value);
                                        d2.current.focus();
                                    }}
                                />

                                <TextField
                                    className={classes.digitos}
                                    id="d2"
                                    name="d2"
                                    inputProps={{
                                        ref: d2,
                                        maxLength: 1
                                    }}
                                    value={digito2}
                                    onChange={(event) => {
                                        setDigito2(event.target.value);
                                        d3.current.focus();
                                    }}
                                />

                                <TextField
                                    className={classes.digitos}
                                    id="d3"
                                    name="d3"
                                    inputProps={{
                                        ref: d3,
                                        maxLength: 1
                                    }}
                                    value={digito3}
                                    onChange={(event) => {
                                        setDigito3(event.target.value);
                                        d4.current.focus();
                                    }}
                                />

                                <TextField
                                    className={classes.digitos}
                                    id="d4"
                                    name="d4"
                                    inputProps={{
                                        ref: d4,
                                        maxLength: 1
                                    }}
                                    value={digito4}
                                    onChange={(event) => {
                                        setDigito4(event.target.value);
                                        d5.current.focus();
                                    }}
                                />

                                <TextField
                                    className={classes.digitos}
                                    id="d5"
                                    name="d5"
                                    inputProps={{
                                        ref: d5,
                                        maxLength: 1
                                    }}
                                    value={digito5}
                                    onChange={(event) => {
                                        setDigito5(event.target.value);
                                        d6.current.focus();
                                    }}
                                />

                                <TextField
                                    className={classes.digitos}
                                    id="d6"
                                    name="d6"
                                    inputProps={{
                                        ref: d6,
                                        maxLength: 1
                                    }}
                                    value={digito6}
                                    onChange={(event) => {
                                        setDigito6(event.target.value);
                                        ns.current.focus();
                                    }}
                                />  
                            </div>

                            {
                                errors.codigoVerificacao &&
                                <FormHelperText className={`${classes.selectError} ${classes.digitoError}`}>{errors.codigoVerificacao ? errors.codigoVerificacao : "" }</FormHelperText>
                            }

                            {
                                enviarNovoCodigo &&
                                <ColorButton                            
                                    variant="contained"
                                    color="primary"
                                    className={classes.buttonCodigoNovo}
                                    onClick={() => {
                                        userService.GerarCodigoRedefinirSenha(emailRedefinir)
                                        .then(() => {
                                            let solicitacaoEmail = {
                                                destinario: emailRedefinir,
                                                assunto : "Futura Portal-CRM | Redefinição de Senha!",
                                                corpoMensagem : htmlRedefinirSenha
                                            };

                                            genericosService.EnviarEmailRedefinirSenha(solicitacaoEmail);
                                        });
                                        setEnviarNovoCodigo(false);
                                        d1.current.focus();
                                    }}>  
                                    Enviar novo código
                                </ColorButton>
                            }
                        </div>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="novaSenha"
                            inputProps={{
                                ref: ns
                            }}
                            label="Nova senha"
                            type="password"
                            id="novaSenha"
                            value={novaSenha}
                            onChange={(event) => setNovaSenha(event.target.value)}
                            onKeyPress={(key) => {
                                if(key.code === 'Enter' || key.code === 'NumpadEnter'){
                                    handleRedefinirSenhaClick();
                                }
                            }}
                        />

                        {
                            errors.novaSenha &&
                            <FormHelperText className={classes.selectError}>{errors.novaSenha ? errors.novaSenha : "" }</FormHelperText>
                        }

                        <ColorButton                            
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleRedefinirSenhaClick}
                            >                                
                            Redefinir senha
                        </ColorButton>

                        <br/>
                        <p>Se você não receber o e-mail em alguns minutos, confira sua pasta de lixeira/spam!</p>
                        <p>Não tem certeza de qual e-mail usou para se cadastrar no CRM, então verifique com o time de RH  da Futura!</p>

                        <Box mt={15}>
                            <VoltarLogin />
                        </Box>
                    </form>
                </Box>
            </Grid>
            <Grid item md={3}>
                
            </Grid>
        </Grid>
    )
}

export default RedefinirSenha;