import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Outlet } from "react-router";
import Header from "./components/Header";
import MenuModulos from "./components/MenuModulos";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  menuModulos: {
    paddingTop: theme.spacing(8),
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes.menuModulos}`}>
      <Header />
      <MenuModulos />
      <Outlet />
    </div>
  );
}

export default Home;
