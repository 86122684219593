import { createContext, useContext, useEffect, useState } from "react";
import controleAcessoService from "../services/controleAcessoService";
import perfilAcesso from "../hooks/PerfilAcesso/usePerfilAcesso";
import { useSelector } from "react-redux";
import { useAuth } from "../components/AuthProvider";
import getPermission from "../hooks/PerfilAcesso/usePermission";
import query from "../pages/components/controls/SQLQuerys/SQLQuery"; 
const Context = createContext([{}, () => {}]);

export const PerfilAcessoProvider = ({ children }) => {
  const { waitingAuthentication } = useAuth();
  const [state, setState] = useState({ perfil: [], permissaoAcessoUsuario: [], routes: [], waitingPerfil: true });
  const account = useSelector(({ account }) => account);

  const getPerfilInfo = () => {
    (async () => {
      try {
        query.clean();
        //Funcionando nova arquitetura 22/03/2022
        //const value = (await controleAcessoService.profile.get(null,query.where('descricao','=',account.usuario.perfil.descricao).getObject()))[0]; 
        const value = (await controleAcessoService.GetPerfil_Legado(account.usuario.perfil.descricao)); 
        const permissaoAcessoUsuario = await controleAcessoService.GetPermissaoAcessoUsuario(account.usuario.cpf);
        const permission = getPermission(permissaoAcessoUsuario, account)

        const { getMenu, getRoutes, getPerfilAcesso, getLinks } = perfilAcesso(permission);

        const perfil = getPerfilAcesso(value.perfilitens);
        const routes = getRoutes(perfil);
        const menu = getMenu(perfil);
        const links = getLinks(perfil);

        setState({ ...state, permissaoAcessoUsuario, perfil, routes, menu, links, waitingPerfil: false });
      } catch (error) {
        if (state.waitingPerfil) {
          setState({ ...state, waitingPerfil: false });
        }
        console.log(error.message || error);
      }
    })();
  };

  useEffect(() => {
    if (waitingAuthentication) return;

    getPerfilInfo();
  }, [waitingAuthentication, account]);

  return <Context.Provider value={[state, setState]}>{children}</Context.Provider>;
};

export const usePerfilAcessoContext = () => useContext(Context);
