import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";
import { usePerfilAcessoContext } from "../../../../contexts/PerfilAcessoContext";
import useStyles from "./styles";
import useStyles2 from "./styles-2";
import utils from "./utils";
import { RenderMenuItemAll, RenderMenuItems, RenderSubMenu, RenderFinalMenu } from "./renders";

const MenuModulos = () => {
  const ref = useRef(null);
  const refSubMenuContainer = useRef(null);
  const classes1 = useStyles();
  const classes2 = useStyles2();
  const [state] = usePerfilAcessoContext();

  const { pathname } = useLocation();
  const firstPath = pathname.replace(/^\/([^/]*).*/i, "$1");

  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [finalMenuOpen, setFinalMenuOpen] = useState(firstPath);
  const [menuModel] = useState(2);
  const classes = menuModel === 1 ? classes1 : classes2;

  // const istouch = window.matchMedia("(pointer: coarse)").matches;
  const istouch = true;
  const deviceType = istouch ? "touch" : "default";

  const { events } = utils({ subMenuOpen, setSubMenuOpen, finalMenuOpen, setFinalMenuOpen, menuModel, deviceType });

  useEffect(() => {
    if (menuModel === 2) {
      setFinalMenuOpen(null);
    }
  }, []);

  const getLeft = (path) => {
    const finalMenuWidth = refSubMenuContainer?.current?.querySelector(`.final-menu-${path}`).offsetWidth;
    const menuItemOffsetLeft = ref?.current?.querySelector(`.menu-${path}`)?.offsetLeft;

    return menuItemOffsetLeft + finalMenuWidth > window.innerWidth
      ? `calc(100vw - ${finalMenuWidth}px - 12px)`
      : menuItemOffsetLeft;
  };

  return (
    state?.menu?.length && (
      <div {...events.rootContainer}>
        <div className={`${classes.root} ${classes.nav} container-nav-menu`} ref={ref}>
          {menuModel === 1 && RenderMenuItemAll(classes, events, istouch, subMenuOpen)}
          {menuModel === 2 && RenderMenuItems(classes, events, state)}
        </div>

        <div
          ref={refSubMenuContainer}
          className={clsx(classes.subMenuContainer, !subMenuOpen && classes.hidden)}
          {...events.subMenuContainer}>
          {menuModel === 1 && RenderSubMenu(classes, events, state)}
          {RenderFinalMenu(classes, events, state, finalMenuOpen, getLeft, menuModel)}
        </div>
      </div>
    )
  );
};

export default MenuModulos;
