import React, { useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { lightBlue } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { signOut } from '../../store/actions/accountActions';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import instance from '../../components/Utils/axios';

import loginGlobal from '../../components/Utils/loginGlobal';

const useStyles = makeStyles((theme) => ({
    avatar: {
        marginLeft: 10
    },
    userName: {
        marginLeft: 10,
        fontWeight: 'bold',
        color: theme.palette.common.black,
    },
    permissaoUser: {
        marginLeft: 10,
        fontWeight: 'bold',
        color: '#6b7280',//theme.palette.common.black,
        fontSize: '11px',
        textAlign: 'right',
    },
    blue: {
        color: theme.palette.getContrastText(lightBlue[800]),
        backgroundColor: theme.palette.common.white,
    },
    img: {
        maxHeight: 25,
        marginLeft: 15,
    },
    divlogo: {
        position: 'fixed',
        right: 10,
    },
    logo: {
        cursor: 'pointer',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #455068',// + lightBlue[200],

        // backgroundColor: lightBlue[50],
        borderRadius: 10,
        margin: '0px 10px',
        '&:hover': {
            backgroundColor: '#ebebeb',//lightBlue[100],
            border: '2px solid #455068',// + theme.palette.primary.main,
        }
    },
    divUserName: {
        display: 'flex',
        flexDirection: 'column'
    },
    divImg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 10
    },
    popover: {
        minWidth: 250,
        minHeight: 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    divTitle: {
        width: '100%',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 600,
        paddingRight: 15,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    divContent: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #DDD',
    },
    divUserPermittionName: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #DDD',
    },
    buttonMenuProfile: {
        border: `1px solid ` + lightBlue[400],
        color: theme.palette.primary.main,
        fontWeight: 600,
        borderRadius: 20,
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 20,
        width: '80%',
        '&:hover': {
            backgroundColor: lightBlue[50],
            border: `2px solid ` + theme.palette.primary.main,
        }
    },
    divFooter: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: '#999',
        '&:hover': {
            color: theme.palette.primary.main,
            fontWeight: 600,
        }
    },
    divMenuPreferencias: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

function LogoHeader() {
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    const ref = useRef();
    const account = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSignOut = () => {
        handleClose();
        dispatch(signOut());
        navigate('/login');
    }

    const handleProfile = () => {
        handleClose();
        navigate('/profile');
    }

    const handleIntranet = () => {
        handleClose();
        navigate('/intranet/0');
    }

    const ambiente = instance.local ? "LOCAL - " : (instance.hml ? 'HML - ' : '');

    return (
        <>
            <div className={classes.divlogo} ref={ref} onClick={handleOpen}>
                <div className={classes.logo} >
                    <div className={classes.divUserName}>
                        <Typography component="span" variant="caption" className={classes.userName}>{ambiente}{account.usuario && account.usuario.nome}</Typography>
                        <Typography component="span" variant="subtitle2" className={classes.permissaoUser}>{account.usuario && account.usuario.perfil && account.usuario.perfil.descricao}</Typography>
                    </div>
                    <div className={classes.divImg}>
                        <img src="/images/Logovertical-Copia2.png" alt="logo" className={classes.img} />
                    </div>
                </div>

            </div>
            <Popover
                onClose={handleClose}
                open={isOpen}
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {/* <Box p={2}>
                    <Typography variant="h6" color="textPrimary">
                        Notificações
                    </Typography>
                </Box> */}
                <div className={classes.popover}>
                    <div className={classes.divTitle}>
                        <div style={{marginLeft: 5}}>v. {loginGlobal && loginGlobal.versaoSistema}</div>
                        <div>{account.usuario && account.usuario.nome}</div>
                    </div>
                    <div className={classes.divContent}>
                        <div className={classes.divUserPermittionName}>
                            {account.usuario && account.usuario.perfil && account.usuario.perfil.descricao} | Futura Sistemas
                        </div>
                        <div className={classes.divMenuPreferencias}>
                            <div className={classes.buttonMenuProfile} onClick={handleProfile}>
                                Visualizar Perfil
                            </div>
                            {/* <div className={classes.buttonMenuProfile} onClick={handleIntranet}>
                            Intranet
                        </div> */}
                        </div>
                    </div>
                    <div className={classes.divFooter} >
                        <div onClick={handleSignOut}>
                            Sair
                        </div>
                    </div>
                </div>
            </Popover>
            {/* <Menu
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={isOpen}
                onClose={handleClose}
                getContentAnchorEl={null}
            >
                <MenuItem onClick={handleProfile}>Perfil</MenuItem>
                <MenuItem onClick={handleSignOut}>Sair</MenuItem>
            </Menu> */}
        </>
    )
}

export default LogoHeader;
